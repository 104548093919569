<template>
    <div class="loginBox h-screen flex items-center bg-stone-200">
      <div class="bg-white h-screen w-screen md:max-w-[900px] md:max-h-[800px] m-auto flex flex-col md:rounded-xl overflow-hidden box-border">
          <div class="h-[66px]">
            <HeaderTop :tabIndex="tabIndex" @tabChange="tabChange"></HeaderTop>
          </div>
          <div class="content flex flex-1">
            <div class="flex-1 flex flex-col">
              <!-- 内容块 -->
              <div class="flex-1 overflow-auto bg-stone-50" ref="scrollBox" :style="loading?'opacity:0':'opacity:1'">
                <Message accept="user" :list="list"></Message>
              </div>
              <!-- 输入块 -->
              <div class="">
                <!-- <div class="text-left p-2 box-border">
                  <el-button class="md:hidden" size="mini" round @click="drawerChange">发送商品</el-button>
                  <el-button class="md:hidden" size="mini" round @click="drawerChange">发送订单</el-button>
                </div> -->
                <div class="border-t p-2 box-border">
                  <el-input
                    input-style="border:none;box-shadow:none"
                    v-model="message"
                    :autosize="{ minRows: 4, maxRows: 4 }"
                    type="textarea"
                    placeholder="Please input"
                  />
                </div>
                <div class="text-right mt-2 px-4 py-2">
                  <el-button @click="onSubmit" type="primary">Submit</el-button>
                </div>
              </div>

            </div>
            <!-- <div class="border-l overflow-auto hidden md:block md:w-[300px]">
              <UserBox></UserBox>
            </div> -->
          </div>
      </div>

      <el-drawer
        size="70%"
        class="max-w-[400px]"
        v-model="drawer"
        title="选择商品"
        :direction="direction">
        <div class="border-t-[1px] py-2">
          <div class="px-2 py-2 rounded-sm mt-2 text-sm bg-slate-50 cursor-pointer" @click="postGoods(item)" v-for="item in goods_list">
                <div class="goods flex items-center">
                    <div class="w-[64px] h-[64px] min-w-[64px] min-h-[64px]">
                      <el-image class="w-full h-full" :src="item.cover" fit="cover" />
                    </div>
                    <div class="cont ml-2 w-[200px] text-left">
                        <div class="name textOver text-sm">{{ item.name }}</div>
                        <div class="info text-xs text-neutral-400 mt-1">
                          {{ item.description }}
                        </div>
                        <div class="mt-1 flex justify-between items-center">
                            <span class="text-sm text-red-500">￥{{ item.price }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </el-drawer>
    </div>
</template>
  
<script>
  import userIcon from "@/assets/user.png";
  import HeaderTop from "@/components/headerTop";
  import Message from "@/components/message";
  import UserBox from "@/components/userBox";
  import { message,product_list } from '@/utils/api'
  import {timeChange} from "@/utils/utils";
    export default {
    components: {HeaderTop,Message,UserBox},
    data:function() {
        return {
          userIcon:userIcon,
          tabIndex:0,//右上角tabs
          message:'',

          // type:'message' 消息类型||其他
          // content_type:'goods', 文本类型
          list:[],
     
          userInfo:null,
          socketIo:'',
          socketEmitter:null,
          loading:false,

          goods_list:[],

          drawer: false,
          direction: 'rtl',
        };
    },
    created() {
    },
    mounted() {
      let userInfo = sessionStorage.getItem('userInfo')||null
      if(!userInfo){
        this.$router.push({
					path:'/login'
				})
        return
      }
      this.userInfo = JSON.parse(userInfo)
      this.getMessage(this.userInfo.id,'load')
      this.$socket.query.token = this.userInfo.mobile
      this.$socket.connect()
      // this.product_list()
    },
    sockets:{
      connect() {
        console.log('链接成功');
      },
      disconnect() {
        console.log('断开链接')
      },
      connect_faild(){
        console.log('失败')
      },
      error(){
        console.log('错误')
      },
      receive(data){
        this.postMessage(data)
      },
      postClient(data){
        if(data.data.role&&data.data.role == 'system'){
          this.postMessage(data.data)
        }
        if(data.data.role&&data.data.role == 'out'){
        }
      }
    },
    methods:{
      async product_list(){
        let res = await product_list()
        this.goods_list = res.data
      },
      drawerChange(){
        this.drawer = true
      },
      scrollBottom(){
        var scrollBox = this.$refs.scrollBox
        var top = scrollBox.scrollHeight - scrollBox.clientHeight
        scrollBox.scrollTop = top>0?top:0

      },

      postMessage(data){
        let arr = this.list
        let obj = data
        if(obj.role == 'system'){
          obj.role = 'assistant'
          arr.push(obj)
          this.list = arr
        }else{
          let user_id = this.userInfo.id
          obj['content_type'] = 'text'
          obj['user_url'] = userIcon
          if(user_id == obj.user_id*1){
            arr.push(obj)
            this.list = arr
          }
        }
        
        setTimeout(()=>{
          this.scrollBottom()
        },100)
      },
      async getMessage(id,type){
        this.loading = true
        let res = await message({user_id:id})
        let list = res.data
        list.map(item=>{
          item['date']=timeChange(item.create_time)
          item['date_time']=(new Date(item.create_time).getTime())/1000
        })
        this.list = list
        if(type == 'load'){
          try{
            setTimeout(()=>{
              this.scrollBottom()
              this.loading = false
            },50)
          }catch(err){
            this.loading = false
            console.log(err)
          }
        }else{
          this.loading = false
        }
      },
   
      tabChange(e){
        this.tabIndex = e
      },
      postGoods(info){
        let obj = {
          type:'goods',
          goods_id:info.id,
          role:'user'
        }
        this.$socket.emit('chat',obj)
        this.drawer = false
        this.getMessage(this.userInfo.id,'load')
      },
      onSubmit(){
        let { message } = this
        if(!message.length) return

        let obj = {
          type:'message',
          content:message,
          role:'user'
        }
          
        this.list.push(obj)

        this.$socket.emit('chat',obj)

        this.message = ''

        setTimeout(()=>{
          this.scrollBottom()
        },100)
      }
    }
  }
</script>
<style scoped lang="scss">
:deep(.el-textarea__inner){
  resize: none;
}
:deep(.el-drawer__header){
  margin-bottom:20px;
}
:deep(.el-drawer__body){
  padding-top:0;
}

.content{
  height:calc(100% - 66px);
}
.textOver{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::-webkit-scrollbar{
    width:5px;
}
::-webkit-scrollbar-track{
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb{
  background-color: #aaa;
}
</style>
  