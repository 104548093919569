<template>
    <div class="w-full h-full p-2 md:p-6">
        <template v-for="(item,index) in list" >
            <div v-if="index==0" class="text-center text-neutral-400 py-4 text-sm">
                <div>{{ item.date }}</div>
            
            </div>
            <div v-else-if="(item.date_time-list[index-1].date_time) > 1800" class="text-center text-neutral-400 py-4 text-sm">
            {{ item.date }}
            </div>

            <div class="flex my-4" :class="item.role == accept?'send':''">
                <div class="w-[40px] h-[40px] min-w-[40px]">
                    <img class="rounded-full overflow-hidden" :src="userIcon" alt="">
                </div>
              <div class="cont">
                <div v-if="item.type =='message'" class="px-4 py-2 mx-4 rounded-xl text-sm" 
                :class="item.role != accept?'bg-slate-100 ':'bg-sky-100 '">
                    {{ item.content }}
                </div>

                <div v-else-if="item.type =='goods'" class="px-4 py-2 mx-4 rounded-xl text-sm" 
                :class="item.role!=accept?'bg-white ':'bg-sky-100 '">
                    <div class="goods flex items-center text-left">
                        <el-image style="width: 72px; height: 72px" :src="item.goods_info.cover" :fit="fit" />
                        <div class="cont ml-2 w-[120px] md:w-[200px]">
                            <div class="name textOver text-sm">{{ item.goods_info.name }}</div>
                            <div class="info textOver text-xs text-neutral-400 mt-1">
                                {{ item.goods_info.description }}
                            </div>

                            <div class="mt-1 flex justify-between items-center">
                                <span class="text-sm text-red-500">￥{{ item.goods_info.price }}</span>
                                <span class="text-xs text-sky-500 cursor-pointer">查看商品 ></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="item.type =='order'" class="px-4 py-2 mx-4 rounded-xl text-sm" 
                :class="item.role!=accept?'bg-white ':'bg-sky-100 '">
                    <div class="goods flex items-center text-left">
                        <el-image style="width: 72px; height: 72px" :src="item.goods_info.cover" :fit="fit" />
                        <div class="cont ml-2 w-[120px] md:w-[200px]">
                            <div class="name textOver text-sm">{{ item.goods_info.name }}</div>
                            <div class="info textOver text-xs text-neutral-400 mt-1">
                                {{ item.goods_info.description }}
                            </div>

                            <div class="mt-1 flex justify-between items-center">
                                <span class="text-sm text-red-500">￥{{ item.goods_info.price }}</span>
                                <span class="text-xs text-sky-500 cursor-pointer">查看商品 ></span>
                            </div>
                        </div>
                    </div>
                </div>

              </div>
              <div class="w-[40px]"></div>
            </div>
        </template>
    </div>
</template>
<script>
import userIcon from "@/assets/user.png";

//type==message 类型
//content_type== text 文本消息 goods 商品
// accept 接收人
// send 发送人
	export default {
		props: {
            accept:{
                type:String
            },
            list:{
                type:Array
            }
		},
        
		data() {
			return {
                fit:'cover',
                userIcon:userIcon
			}
		},
		created() {
		},
		mounted() {
		},
		methods: {
			
		},
	}
</script>

<style scoped lang="scss">
.textOver{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.send{
    flex-flow:row-reverse;
}

</style>
