<template>
    <div class="flex justify-between items-center bg-sky-500 px-4 h-full">
        <div class="left flex items-center">
           
        </div>
        <div class="right flex items-center text-white h-full">
			<!-- <el-button round @click="outLogin">退出登录</el-button> -->
			<div class="text-sm flex items-center h-full hidden md:block">
				<div @click="onTab(index)" class="ml-6 cursor-pointer h-full flex items-center" :class="tabIndex==index?'font-bold border-b-2':''" v-for="(item,index) in tabS">{{ item }}</div>
			</div>
        </div>
    </div>
</template>
<script>
	export default {
		props: {
			tabIndex:{
				type:Number||String,
				default:0
			}
		},
		data() {
			return {
				query:{
					search:'',
				},
				tabS:[],
			}
		},
		created() {
		},
		mounted() {
		},
		methods: {
			outLogin(){
				this.$confirm(
					'是否退出登录',
					'提示',
					{
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning',
					}
				).then(() => {
					sessionStorage.removeItem('userInfo')
					this.$router.push({
						path:'/login'
					})
				})
			},
			onTab(e){
				this.$emit('tabChange',e)
			}
		},
	}
</script>

<style scoped lang="scss">

</style>
