import request from '@/utils/request'
/**
 * @param {array} params 请求参数
 */
export function register(data) {
    return request({
      url: '/get_user/register',
      method: 'post',
      data
    })
}
export function login(data) {
  return request({
    url: '/get_user/login',
    method: 'post',
    data
  })
}

export function thread(data) {
  return request({
    url: '/thread/service_list',
    method: 'get',
    params:data
  })
}

export function message(data) {
  return request({
    url: '/message/list',
    method: 'get',
    params:data
  })
}

export function product_list(data) {
  return request({
    url: '/product/list',
    method: 'get',
    params:data
  })
}

export function login_user(data) {
  return request({
    url: '/get_user_info/info',
    method: 'get',
    params:data
  })
}