import { createRouter, createWebHashHistory } from 'vue-router'
import indexView from '@/views/index/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: indexView
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/authuser',
    name: 'authuser',
    component: () => import('@/views/authuser/authuser.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
