<template>
    <div class="w-full h-full flex flex-col">
        <div class="flex items-center px-2 py-4">
            <img class="rounded-full overflow-hidden w-[40px] ml-4" :src="userIcon" alt="">
            <span class="ml-4">wx548506</span>
            <el-tag class="ml-2">小程序</el-tag>
        </div>
        <div class="border-t p-2 text-sm">
            <div class="flex my-2">
                <div class="w-[80px] text-neutral-500">手机号</div>
                <div class="flex-1">暂无</div>
            </div>
            <div class="flex my-2">
                <div class="w-[80px] text-neutral-500">推荐人</div>
                <div class="flex-1">暂无</div>
            </div>
            <div class="flex my-2">
                <div class="w-[80px] text-neutral-500">用户类型</div>
                <div class="flex-1">暂无</div>
            </div>
            <div class="flex my-2">
                <div class="w-[80px] text-neutral-500">余额</div>
                <div class="flex-1">999999</div>
            </div>
            <div class="flex my-2">
                <div class="w-[80px] text-neutral-500">推广员</div>
                <div class="flex-1">是  </div>
            </div>
        </div>
    </div>
</template>
<script>
    import userIcon from "@/assets/user.png";

	export default {
		props: {
			tabIndex:{
				type:Number||String,
				default:0
			}
		},
		data() {
			return {
                userIcon,
                contact_tabs:['用户列表','游客列表'],
                contact_type:0,
                userS:[1,2,3,4,5,6],
                userIndex:0,
			}
		},
		created() {
		},
		mounted() {
		},
		methods: {
            userTabChange(e){
                this.contact_type = e
            },
		},
	}
</script>

<style scoped lang="scss">

</style>
